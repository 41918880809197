<template lang="pug">
  main#main.manager-identities
    b-container(fluid)
      wc-breadcrumb(icon='fal fa-shield-alt')
      section.l-section
        .l-section-h
          router-view
</template>

<script>
import WcBreadcrumb from '@components/shared/WcBreadcrumb'

export default {
  name: 'manager-identities',
  components: {
    WcBreadcrumb,
  },
}
</script>
